<script lang="ts">
  import { state } from '../store/state'
  import Splash from '../components/splash/splash.svelte'

  let shardCreationInProgress = state.shardCreationInProgress
  let loading = state.isLoading
  let total = state.totalShardToLoad
  let loaded = state.shardLoaded
</script>

{#if $loading}
  <Splash total={$total} loaded={$loaded} shardCreationInProgress={$shardCreationInProgress} />
{:else}
  <div class="app-shell flex flex-col min-w-screen">
    <div>
      <slot name="modal" />
      <div class="fixed top-0 left-12 z-50 min-w-[calc(100vw-var(--bars-size))]">
        <slot name="tabs" />
      </div>
      <div class="flex flex-row">
        <div class="fixed top-0 left-0 z-50">
          <slot name="sidebar" />
        </div>
        <div class="sidebar-border" />
        <div
          class="absolute top-12 left-12 z-0 h-[calc(100vh-var(--bars-size))] w-[calc(100vw-var(--bars-size))] max-h-[calc(100vh-var(--bars-size))] max-w-[calc(100vw-var(--bars-size))]"
        >
          <slot />
        </div>
      </div>
      <Splash fadeOut={true} total={$total} loaded={$loaded} />
    </div>
  </div>
  <slot name="toast" />
{/if}

<style lang="postcss">
  .sidebar-border {
    border-inline-end: 0.5px solid var(--selected);
    height: 100vh;
    width: var(--bars-size);
  }
</style>
