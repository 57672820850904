<svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  width="3226px"
  height="922px"
  style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 3226 922"
>
  <g
    ><path
      style="opacity:0.989"
      fill="#1f2b44"
      d="M 1827.5,-0.5 C 1857.83,-0.5 1888.17,-0.5 1918.5,-0.5C 1918.5,25.5 1918.5,51.5 1918.5,77.5C 1951.5,77.5 1984.5,77.5 2017.5,77.5C 2017.46,102.244 2021.62,126.244 2030,149.5C 2032.62,155.914 2036.12,161.747 2040.5,167C 1999.83,167.5 1959.17,167.667 1918.5,167.5C 1918.33,205.501 1918.5,243.501 1919,281.5C 1924.86,311.691 1942.69,330.525 1972.5,338C 1999.8,339.447 2027.13,339.947 2054.5,339.5C 2054.5,369.5 2054.5,399.5 2054.5,429.5C 2025.83,429.667 1997.16,429.5 1968.5,429C 1906.32,419.67 1862.82,386.17 1838,328.5C 1831.98,312.716 1828.65,296.383 1828,279.5C 1827.5,242.168 1827.33,204.835 1827.5,167.5C 1813.15,167.833 1798.82,167.499 1784.5,166.5C 1792.57,158.364 1798.07,148.697 1801,137.5C 1806.12,117.813 1808.96,97.8131 1809.5,77.5C 1815.5,77.5 1821.5,77.5 1827.5,77.5C 1827.5,51.5 1827.5,25.5 1827.5,-0.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.992"
      fill="#1f2b44"
      d="M 2656.5,63.5 C 2728.79,62.3807 2783.62,92.3807 2821,153.5C 2845.78,203.428 2851.12,255.428 2837,309.5C 2816.38,377.788 2771.21,419.288 2701.5,434C 2672.87,440.264 2644.87,438.264 2617.5,428C 2603.1,421.967 2589.1,415.134 2575.5,407.5C 2574.5,459.829 2574.17,512.162 2574.5,564.5C 2544.17,564.5 2513.83,564.5 2483.5,564.5C 2483.5,401.833 2483.5,239.167 2483.5,76.5C 2491.2,76.1692 2498.86,76.5025 2506.5,77.5C 2517.81,91.3131 2529.31,104.98 2541,118.5C 2573.28,87.1078 2611.78,68.7745 2656.5,63.5 Z M 2660.5,154.5 C 2705.69,155.413 2735.36,177.246 2749.5,220C 2758.98,256.643 2751.82,289.476 2728,318.5C 2698.93,345.513 2665.76,352.013 2628.5,338C 2598.26,322.738 2580.76,298.238 2576,264.5C 2571.86,233.72 2579.52,206.387 2599,182.5C 2616.03,165.399 2636.53,156.066 2660.5,154.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.989"
      fill="#1f2b44"
      d="M 3035.5,63.5 C 3097.2,59.2841 3149.03,79.6174 3191,124.5C 3194.96,129.595 3198.79,134.762 3202.5,140C 3147.04,207.634 3091.37,275.134 3035.5,342.5C 3080.67,350.246 3114.01,333.913 3135.5,293.5C 3157.49,314.986 3179.32,336.653 3201,358.5C 3201.67,359.5 3201.67,360.5 3201,361.5C 3161.16,414.164 3107.99,438.997 3041.5,436C 2962.43,427.254 2909.59,385.421 2883,310.5C 2867.23,254.509 2872.9,200.842 2900,149.5C 2931.47,99.5022 2976.63,70.8356 3035.5,63.5 Z M 3050.5,154.5 C 3056.85,154.735 3063.18,155.235 3069.5,156C 3072.79,156.253 3075.79,157.253 3078.5,159C 3043.88,204.41 3009.38,249.91 2975,295.5C 2962.8,263.375 2963.8,231.709 2978,200.5C 2994.24,172.633 3018.4,157.3 3050.5,154.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.753"
      fill="#1f4964"
      d="M -0.5,65.5 C 75.1667,65.5 150.833,65.5 226.5,65.5C 226.5,197.167 226.5,328.833 226.5,460.5C 225.5,329.334 225.167,198.001 225.5,66.5C 150.5,66.5 75.5,66.5 0.5,66.5C 0.824527,73.6866 0.491194,80.6866 -0.5,87.5C -0.5,80.1667 -0.5,72.8333 -0.5,65.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.995"
      fill="#14aecc"
      d="M 226.5,65.5 C 229.359,133.391 255.693,190.557 305.5,237C 373.479,292.794 449.479,307.127 533.5,280C 611.35,248.808 659.85,192.308 679,110.5C 681.422,95.4227 683.256,80.4227 684.5,65.5C 684.5,197.167 684.5,328.833 684.5,460.5C 684.5,460.833 684.5,461.167 684.5,461.5C 556.255,531.381 423.588,540.881 286.5,490C 265.81,481.821 245.81,472.321 226.5,461.5C 226.5,461.167 226.5,460.833 226.5,460.5C 226.5,328.833 226.5,197.167 226.5,65.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.997"
      fill="#1e2b44"
      d="M 226.5,460.5 C 226.5,460.833 226.5,461.167 226.5,461.5C 226.5,614.833 226.5,768.167 226.5,921.5C 150.833,921.5 75.1667,921.5 -0.5,921.5C -0.5,643.5 -0.5,365.5 -0.5,87.5C 0.491194,80.6866 0.824527,73.6866 0.5,66.5C 75.5,66.5 150.5,66.5 225.5,66.5C 225.167,198.001 225.5,329.334 226.5,460.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:1"
      fill="#1f4661"
      d="M 684.5,65.5 C 760.335,65.1674 836.002,65.5007 911.5,66.5C 836.167,66.5 760.833,66.5 685.5,66.5C 685.833,198.001 685.5,329.334 684.5,460.5C 684.5,328.833 684.5,197.167 684.5,65.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.997"
      fill="#1f2b44"
      d="M 911.5,66.5 C 911.5,351.5 911.5,636.5 911.5,921.5C 835.833,921.5 760.167,921.5 684.5,921.5C 684.5,768.167 684.5,614.833 684.5,461.5C 684.5,461.167 684.5,460.833 684.5,460.5C 685.5,329.334 685.833,198.001 685.5,66.5C 760.833,66.5 836.167,66.5 911.5,66.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.993"
      fill="#14aecc"
      d="M 966.5,75.5 C 998.167,75.5 1029.83,75.5 1061.5,75.5C 1063.62,113.6 1081.29,141.766 1114.5,160C 1158,177.768 1195.83,169.601 1228,135.5C 1241.8,117.935 1248.97,97.9351 1249.5,75.5C 1281.17,75.5 1312.83,75.5 1344.5,75.5C 1344.5,193.5 1344.5,311.5 1344.5,429.5C 1312.83,429.5 1281.17,429.5 1249.5,429.5C 1249.67,366.166 1249.5,302.832 1249,239.5C 1203.71,265.015 1155.88,271.181 1105.5,258C 1090.18,253.781 1075.68,247.614 1062,239.5C 1061.5,302.832 1061.33,366.166 1061.5,429.5C 1029.83,429.5 998.167,429.5 966.5,429.5C 966.5,311.5 966.5,193.5 966.5,75.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.99"
      fill="#14aecc"
      d="M 1376.5,75.5 C 1408.5,75.5 1440.5,75.5 1472.5,75.5C 1475.77,124.943 1501.44,155.777 1549.5,168C 1586.74,173.259 1617.24,161.425 1641,132.5C 1653.17,115.491 1659.67,96.4914 1660.5,75.5C 1692.17,75.5 1723.83,75.5 1755.5,75.5C 1752.07,155.911 1714.4,213.745 1642.5,249C 1580.48,273.737 1520.81,269.07 1463.5,235C 1407.79,196.765 1378.79,143.598 1376.5,75.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.99"
      fill="#1f2b44"
      d="M 2062.5,75.5 C 2094.5,75.5 2126.5,75.5 2158.5,75.5C 2161.77,124.943 2187.44,155.777 2235.5,168C 2272.74,173.259 2303.24,161.425 2327,132.5C 2339.17,115.491 2345.67,96.4914 2346.5,75.5C 2378.17,75.5 2409.83,75.5 2441.5,75.5C 2438.07,155.911 2400.4,213.745 2328.5,249C 2266.48,273.737 2206.81,269.07 2149.5,235C 2093.79,196.762 2064.79,143.595 2062.5,75.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.991"
      fill="#14aecc"
      d="M 1518.5,320.5 C 1550.05,328.389 1581.55,328.389 1613,320.5C 1613.5,356.832 1613.67,393.165 1613.5,429.5C 1581.83,429.5 1550.17,429.5 1518.5,429.5C 1518.5,393.167 1518.5,356.833 1518.5,320.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.991"
      fill="#1f2b44"
      d="M 2204.5,320.5 C 2236.05,328.389 2267.55,328.389 2299,320.5C 2299.5,356.832 2299.67,393.165 2299.5,429.5C 2267.83,429.5 2236.17,429.5 2204.5,429.5C 2204.5,393.167 2204.5,356.833 2204.5,320.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.966"
      fill="#1f2b44"
      d="M 1160.5,624.5 C 1183.84,624.005 1194.67,635.338 1193,658.5C 1188.66,671.509 1179.66,678.509 1166,679.5C 1147.16,677.002 1138.16,666.336 1139,647.5C 1141.23,635.107 1148.4,627.44 1160.5,624.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.966"
      fill="#1f2b44"
      d="M 1414.5,624.5 C 1438.31,624.142 1449.14,635.809 1447,659.5C 1442.31,671.839 1433.31,678.505 1420,679.5C 1403.5,677.667 1394.33,668.5 1392.5,652C 1393.79,637.885 1401.13,628.718 1414.5,624.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.965"
      fill="#14aecc"
      d="M 1957.5,624.5 C 1976.85,623.688 1987.85,632.855 1990.5,652C 1986.45,674.197 1973.45,682.53 1951.5,677C 1935.73,667.675 1931.56,654.508 1939,637.5C 1943.61,630.792 1949.78,626.459 1957.5,624.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.964"
      fill="#1f2b44"
      d="M 2809.5,624.5 C 2829.14,623.143 2840.47,632.143 2843.5,651.5C 2841.92,668.25 2832.75,677.583 2816,679.5C 2802.26,678.413 2793.26,671.413 2789,658.5C 2786.61,641.431 2793.44,630.097 2809.5,624.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.982"
      fill="#1f2b44"
      d="M 1074.5,631.5 C 1088.17,631.5 1101.83,631.5 1115.5,631.5C 1115.5,705.167 1115.5,778.833 1115.5,852.5C 1111.82,852.665 1108.15,852.499 1104.5,852C 1099.54,845.254 1094.71,838.421 1090,831.5C 1058.17,859.867 1024,863.033 987.5,841C 962.828,821.142 951.661,795.309 954,763.5C 958.186,726.954 977.686,702.787 1012.5,691C 1028.71,687.142 1044.71,687.809 1060.5,693C 1065.86,695.354 1070.36,698.854 1074,703.5C 1074.5,679.502 1074.67,655.502 1074.5,631.5 Z M 1029.5,729.5 C 1056.22,731.053 1071.22,745.22 1074.5,772C 1071.96,796.202 1058.79,810.369 1035,814.5C 1010.24,810.903 997.073,796.57 995.5,771.5C 996.287,748.734 1007.62,734.734 1029.5,729.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.976"
      fill="#1f2b44"
      d="M 1479.5,631.5 C 1493.17,631.5 1506.83,631.5 1520.5,631.5C 1520.5,652.5 1520.5,673.5 1520.5,694.5C 1540.83,694.5 1561.17,694.5 1581.5,694.5C 1581.5,708.167 1581.5,721.833 1581.5,735.5C 1561.17,735.5 1540.83,735.5 1520.5,735.5C 1520.33,753.17 1520.5,770.836 1521,788.5C 1524.58,800.415 1532.41,807.915 1544.5,811C 1556.83,811.5 1569.16,811.667 1581.5,811.5C 1581.5,825.167 1581.5,838.833 1581.5,852.5C 1567.5,852.667 1553.5,852.5 1539.5,852C 1506.78,845.615 1486.95,826.115 1480,793.5C 1479.5,774.17 1479.33,754.836 1479.5,735.5C 1472.83,735.5 1466.17,735.5 1459.5,735.5C 1459.5,721.833 1459.5,708.167 1459.5,694.5C 1466.17,694.5 1472.83,694.5 1479.5,694.5C 1479.5,673.5 1479.5,652.5 1479.5,631.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.968"
      fill="#1f2b44"
      d="M 1785.5,631.5 C 1799.5,631.5 1813.5,631.5 1827.5,631.5C 1827.5,705.167 1827.5,778.833 1827.5,852.5C 1813.5,852.5 1799.5,852.5 1785.5,852.5C 1785.5,778.833 1785.5,705.167 1785.5,631.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.962"
      fill="#1f2b44"
      d="M 2723.5,631.5 C 2737.5,631.5 2751.5,631.5 2765.5,631.5C 2765.5,705.167 2765.5,778.833 2765.5,852.5C 2751.5,852.5 2737.5,852.5 2723.5,852.5C 2723.5,778.833 2723.5,705.167 2723.5,631.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.993"
      fill="#14aecc"
      d="M 569.5,921.5 C 493.5,921.5 417.5,921.5 341.5,921.5C 341.5,834.167 341.5,746.833 341.5,659.5C 399.242,672.893 457.576,676.393 516.5,670C 534.378,667.458 552.045,663.958 569.5,659.5C 569.5,746.833 569.5,834.167 569.5,921.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.982"
      fill="#1f2b44"
      d="M 1275.5,688.5 C 1302.12,685.903 1324.95,693.903 1344,712.5C 1349.21,706.958 1354.04,701.125 1358.5,695C 1362.15,694.501 1365.82,694.335 1369.5,694.5C 1369.67,744.834 1369.5,795.168 1369,845.5C 1358.9,887.593 1332.24,910.26 1289,913.5C 1282.19,912.639 1275.36,912.306 1268.5,912.5C 1268.5,898.833 1268.5,885.167 1268.5,871.5C 1278.51,871.666 1288.51,871.5 1298.5,871C 1311.46,868.703 1320.13,861.37 1324.5,849C 1324.17,848.5 1323.83,848 1323.5,847.5C 1316.49,851.893 1308.82,854.393 1300.5,855C 1265.49,859.072 1238.32,846.572 1219,817.5C 1203.36,786.982 1203.69,756.649 1220,726.5C 1233.24,706.08 1251.74,693.413 1275.5,688.5 Z M 1284.5,729.5 C 1306.21,730.032 1320.38,740.698 1327,761.5C 1330.94,786.129 1321.44,803.296 1298.5,813C 1268.29,816.136 1251.96,802.47 1249.5,772C 1250.18,748.504 1261.85,734.338 1284.5,729.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.975"
      fill="#1f2b44"
      d="M 1662.5,688.5 C 1688.85,685.892 1711.35,693.892 1730,712.5C 1735.17,706.667 1740.33,700.833 1745.5,695C 1749.15,694.501 1752.82,694.335 1756.5,694.5C 1756.5,747.167 1756.5,799.833 1756.5,852.5C 1752.82,852.665 1749.15,852.499 1745.5,852C 1740.21,845.256 1735.05,838.423 1730,831.5C 1703.93,856.047 1674.1,861.88 1640.5,849C 1609.46,833.103 1594.12,807.603 1594.5,772.5C 1595.55,726.798 1618.22,698.798 1662.5,688.5 Z M 1670.5,729.5 C 1697.41,730.91 1712.08,745.077 1714.5,772C 1712.7,796.801 1699.53,810.968 1675,814.5C 1649.79,811.619 1636.62,797.452 1635.5,772C 1636.16,748.524 1647.83,734.357 1670.5,729.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.978"
      fill="#14aecc"
      d="M 2072.5,688.5 C 2113.34,684.491 2142.5,700.824 2160,737.5C 2173.12,774.912 2165.95,807.745 2138.5,836C 2109.12,858.551 2077.79,861.551 2044.5,845C 2010.42,821.454 1997.92,789.287 2007,748.5C 2016.97,715.354 2038.81,695.354 2072.5,688.5 Z M 2080.5,729.5 C 2108.08,730.242 2122.74,744.409 2124.5,772C 2123.41,797.426 2110.24,811.593 2085,814.5C 2060.5,810.996 2047.33,796.829 2045.5,772C 2046.95,749.081 2058.61,734.914 2080.5,729.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.975"
      fill="#1f2b44"
      d="M 2600.5,688.5 C 2626.85,685.892 2649.35,693.892 2668,712.5C 2673.02,706.98 2677.85,701.314 2682.5,695.5C 2686.45,694.509 2690.45,694.176 2694.5,694.5C 2694.5,747.167 2694.5,799.833 2694.5,852.5C 2690.45,852.824 2686.45,852.491 2682.5,851.5C 2677.91,844.656 2673.08,837.99 2668,831.5C 2636.47,859.578 2602.3,863.078 2565.5,842C 2539,820.545 2528.17,792.712 2533,758.5C 2540.15,720.183 2562.65,696.85 2600.5,688.5 Z M 2608.5,729.5 C 2635.41,730.91 2650.08,745.077 2652.5,772C 2650.58,796.706 2637.41,810.872 2613,814.5C 2587.83,811.657 2574.66,797.49 2573.5,772C 2574.2,748.488 2585.87,734.321 2608.5,729.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.978"
      fill="#1f2b44"
      d="M 2429.5,691.5 C 2470.57,688.103 2497.73,705.769 2511,744.5C 2512.03,749.785 2512.69,755.118 2513,760.5C 2513.5,791.165 2513.67,821.832 2513.5,852.5C 2499.5,852.5 2485.5,852.5 2471.5,852.5C 2471.67,820.165 2471.5,787.832 2471,755.5C 2466.72,741.387 2457.22,733.721 2442.5,732.5C 2428.9,733.379 2419.4,740.045 2414,752.5C 2412.53,785.784 2412.03,819.118 2412.5,852.5C 2398.83,852.5 2385.17,852.5 2371.5,852.5C 2371.5,799.833 2371.5,747.167 2371.5,694.5C 2375.18,694.335 2378.85,694.501 2382.5,695C 2386.31,699.649 2390.31,704.149 2394.5,708.5C 2405.05,700.389 2416.72,694.722 2429.5,691.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.978"
      fill="#1f2b44"
      d="M 3225.5,746.5 C 3225.5,781.833 3225.5,817.167 3225.5,852.5C 3211.83,852.5 3198.17,852.5 3184.5,852.5C 3184.67,818.165 3184.5,783.832 3184,749.5C 3178.7,733.933 3168.53,729.433 3153.5,736C 3149.9,738.708 3147.4,742.208 3146,746.5C 3145.5,781.832 3145.33,817.165 3145.5,852.5C 3131.83,852.5 3118.17,852.5 3104.5,852.5C 3104.67,818.832 3104.5,785.165 3104,751.5C 3102.28,740.277 3095.78,733.944 3084.5,732.5C 3075.3,733.201 3069.14,737.867 3066,746.5C 3064.54,781.798 3064.04,817.132 3064.5,852.5C 3050.83,852.5 3037.17,852.5 3023.5,852.5C 3023.5,799.833 3023.5,747.167 3023.5,694.5C 3027.51,694.334 3031.51,694.501 3035.5,695C 3038.3,698.799 3041.46,702.299 3045,705.5C 3066.47,689.404 3089.3,687.237 3113.5,699C 3117.31,701.98 3120.98,705.146 3124.5,708.5C 3135.04,696.642 3148.37,690.976 3164.5,691.5C 3199.12,694.123 3219.45,712.456 3225.5,746.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.992"
      fill="#1f2b44"
      d="M 1145.5,694.5 C 1159.17,694.5 1172.83,694.5 1186.5,694.5C 1186.5,747.167 1186.5,799.833 1186.5,852.5C 1172.83,852.5 1159.17,852.5 1145.5,852.5C 1145.5,799.833 1145.5,747.167 1145.5,694.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.969"
      fill="#1f2b44"
      d="M 1399.5,694.5 C 1413.5,694.5 1427.5,694.5 1441.5,694.5C 1441.5,747.167 1441.5,799.833 1441.5,852.5C 1427.5,852.5 1413.5,852.5 1399.5,852.5C 1399.5,799.833 1399.5,747.167 1399.5,694.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.98"
      fill="#14aecc"
      d="M 1941.5,694.5 C 1955.5,694.5 1969.5,694.5 1983.5,694.5C 1983.67,742.501 1983.5,790.501 1983,838.5C 1978.43,875.063 1958.6,898.896 1923.5,910C 1920.21,910.882 1916.88,911.548 1913.5,912C 1902.84,912.5 1892.17,912.667 1881.5,912.5C 1881.5,898.833 1881.5,885.167 1881.5,871.5C 1891.17,871.666 1900.84,871.5 1910.5,871C 1927.11,866.321 1937.28,855.488 1941,838.5C 1941.5,790.501 1941.67,742.501 1941.5,694.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.977"
      fill="#14aecc"
      d="M 2179.5,694.5 C 2193.83,694.5 2208.17,694.5 2222.5,694.5C 2224.71,719.872 2238.54,733.872 2264,736.5C 2282.65,735.01 2295.65,725.677 2303,708.5C 2304.54,703.955 2305.38,699.289 2305.5,694.5C 2319.83,694.5 2334.17,694.5 2348.5,694.5C 2345.97,737.185 2324.3,764.685 2283.5,777C 2243.25,784.116 2212.08,770.616 2190,736.5C 2182.74,723.444 2179.24,709.444 2179.5,694.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.969"
      fill="#1f2b44"
      d="M 2794.5,694.5 C 2808.5,694.5 2822.5,694.5 2836.5,694.5C 2836.5,747.167 2836.5,799.833 2836.5,852.5C 2822.5,852.5 2808.5,852.5 2794.5,852.5C 2794.5,799.833 2794.5,747.167 2794.5,694.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.977"
      fill="#1f2b44"
      d="M 2902.5,694.5 C 2932.83,694.5 2963.17,694.5 2993.5,694.5C 2993.5,708.167 2993.5,721.833 2993.5,735.5C 2965.16,735.333 2936.83,735.5 2908.5,736C 2899.61,741.483 2899.27,747.483 2907.5,754C 2924.83,754.333 2942.17,754.667 2959.5,755C 2989.99,760.48 3004.49,778.647 3003,809.5C 2998.28,832.553 2984.45,846.719 2961.5,852C 2927.83,852.5 2894.17,852.667 2860.5,852.5C 2860.5,838.833 2860.5,825.167 2860.5,811.5C 2892.5,811.667 2924.5,811.5 2956.5,811C 2962.75,807.243 2963.75,802.576 2959.5,797C 2958.17,796.667 2956.83,796.333 2955.5,796C 2937.5,795.667 2919.5,795.333 2901.5,795C 2875.74,788.239 2862.07,771.572 2860.5,745C 2862.37,717.971 2876.37,701.138 2902.5,694.5 Z"
    /></g
  >
  <g
    ><path
      style="opacity:0.969"
      fill="#14aecc"
      d="M 2242.5,803.5 C 2256.85,807.381 2271.18,807.381 2285.5,803.5C 2285.5,819.833 2285.5,836.167 2285.5,852.5C 2271.17,852.5 2256.83,852.5 2242.5,852.5C 2242.5,836.167 2242.5,819.833 2242.5,803.5 Z"
    /></g
  >
</svg>
