const messages = [
  'Firing up the warp drive...',
  '42',
  'Unleashing the Kraken...',
  'Charging the flux capacitor...',
  'Summoning a blue police box...',
  'Entering hyperspace...',
  'Dialing the Stargate...',
  'Engaging the FTL drive...',
  'Plotting a course to the second star on the right...',
  'Waking up the dragons...',
  'Brewing some polyjuice potion...',
  'Calling the Millennium Falcon...',
  'Beaming up Scotty...',
  'Opening a portal to another dimension...',
  'Riding a broomstick...',
  'Summoning a Patronus...',
  'Casting a spell...',
  'Using the Force...',
  'Entering the Matrix...',
  'Traveling through time...',
  'Exploring the quantum realm...',
  'Searching for the Holy Grail...',
  'Building a time machine...',
  'Solving the Riemann Hypothesis...',
  'Cracking the Enigma code...',
  'Discovering the Higgs boson...',
  'Creating a perpetual motion machine...',
  'Unlocking the secrets of the universe...',
  'Finding the lost city of Atlantis...',
  'Unraveling the mysteries of dark matter...',
  'Hacking into the mainframe...',
  'Creating a virtual reality world...',
  'Building an AI that passes the Turing test...',
  'Cracking the Da Vinci Code...',
  'Decoding alien messages...',
  'Inventing a teleportation device...',
  'Creating a time loop...',
  'Exploring parallel universes...',
  'Building a quantum computer...',
  'Discovering the meaning of life...',
  'Solving the P vs NP problem...',
  'Creating a fusion reactor...',
  'Designing a self-driving car...',
  'Building a robot army...',
  'Developing a cure for aging...',
  'Creating a mind-reading device...',
  'Inventing a device to control the weather...',
  'Building a space elevator...',
  'Creating a machine that can see the future...',
  'Solving the mysteries of black holes...',
  'Developing a universal translator...',
  'Building a Dyson sphere...',
  'Creating a device to communicate with dolphins...',
  'Inventing a device to control dreams...',
  'Building a colony on Mars...',
  'Creating a device to travel to parallel dimensions...',
  'Solving the problem of world hunger...',
  'Developing a cure for all diseases...',
  'Building a time-traveling DeLorean...',
  'Creating a device to communicate with animals...',
  'Inventing a device to control gravity...',
  'Building a floating city...',
  'Creating a device to read thoughts...',
  'Solving the mysteries of the pyramids...',
  'Creating a device to predict earthquakes...',
  'Building a underwater city...',
  'Creating a device to teleport objects...',
  'Creating a device to communicate with plants...',
  'Building a colony on Venus...',
  'Creating a device to communicate with extraterrestrial life...',
  'Inventing a device to control luck...',
  'Building a city on Mars...',
  'Creating a device to communicate with ghosts...',
  'Solving the mysteries of the lost city of Atlantis...',
]

export function getRandomMessage() {
  const randomIndex = Math.floor(Math.random() * messages.length)
  return messages[randomIndex]
}
