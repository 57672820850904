<script lang="ts">
  import { ProgressBar } from 'carbon-components-svelte'
  import SplashSvg from './splash-svg.svelte'
  import { getRandomMessage } from './funny'
  import { quantityOfBlockingShards } from '../../constants'
  import StyleProgressbar from '../../style/style-progressbar.svelte'

  export let shardCreationInProgress = false
  export let total = 0
  export let loaded = 0
  export let fadeOut = false

  let description = 'Loading data...'
  let remove = false

  if (fadeOut) {
    description = 'done'
    setTimeout(() => {
      remove = true
    }, 5000)
  } else {
    setInterval(() => {
      description = getRandomMessage()
    }, 3800)
  }
</script>

<div
  class="splash-wrapper flex flex-col items-center justify-center overflow-hidden bg-[var(--hover-color)] relative z-20"
  class:hidden={remove}
>
  <div class="svg-image">
    <SplashSvg />
  </div>
  <div class="funny-wrapper flex flex-col items-center min-w-[20rem]">
    {#if shardCreationInProgress}
      <p class="text-center text-[var(--cds-danger-01)] text-2xl font-bold">
        System is initializing for the first time...
      </p>
    {:else}
      <StyleProgressbar minWidth={'20rem'}>
        <ProgressBar value={loaded + 1} max={quantityOfBlockingShards(total)} helperText={description} />
      </StyleProgressbar>
    {/if}
  </div>
</div>

<style>
  .splash-wrapper {
    min-height: 100vh;
    min-width: 100vw;
  }
  .svg-image {
    transform: scale(0.2);
  }
  .funny-wrapper {
    transform: translateY(-20rem);
    min-width: 20rem;
  }
</style>
